import { ReactNode } from 'react';
import dayjs from 'dayjs';
import type { OfferType } from '@super-protocol/sdk-js';
import getConfig from 'config';
import { OfferRestrictions, TOfferType, Offer } from 'generated/types';
// import { OfferTypeTextBtn } from 'components/AdderBtn/AdderBtnOffer/types';
import { Web3ContractError } from 'web3';

type Unit = 's' | 'date' | 'month' | 'week' | 'minute';

export const getQSFromObj = (params: object): string => {
  const qs = Object
    .entries(params)
    .filter(([, value]) => value)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value as string)}`)
    .join('&');
  return qs ? `?${qs}` : '';
};

export const sliceWithDot = (str?: string, lenFrom = 6, lenTo = 6): string => {
  if (!str) return '';
  if (str.length < lenFrom) return str;
  return `${str.slice(0, lenFrom)}...${str.slice(str.length - lenTo)}`;
};

export const getStringWithEllipsis = (str: string, len: number): string => {
  return str && str.length > len ? `${str.substring(0, len)}...` : str;
};

export const getTableDate = (date?: number | string, showSeconds?: boolean): string => {
  if (!date) return '-';
  const dj = dayjs(date);
  const formatedData = showSeconds ? 'DD.MM.YYYY HH:mm:ss' : 'DD.MM.YYYY HH:mm';
  return dj.isValid() ? dj.format(formatedData) : '-';
};

export const compareDates = (date?: number, unit: Unit = 'minute'): number | undefined => {
  if (!date) return undefined;
  const date1 = dayjs(date);
  const date2 = dayjs();

  return date2.diff(date1, unit as Unit);
};

export const capitalize = (s: string) => (s && s[0].toUpperCase() + s.slice(1)) || '';

export const isSSR = () => typeof window === 'undefined';

export const JSONParseSafe = (str: string): any => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return null;
  }
};

export const getAuthorizationHeader = (token?: string | null): string => (token ? `Bearer ${token}` : '');

export const getQueryName = (query?: string): string => (query ? /(query)\s*(\w+)/.exec(query)?.[2] ?? '' : '');

export const boolToStr = (data: boolean): string => (data ? 'yes' : 'no');

export const getRestrictions = (offerRestrictions?: OfferRestrictions | null): { id: string; offerType?: string }[] => {
  return (offerRestrictions?.offers || []).map((id, index) => ({ id, offerType: offerRestrictions?.types?.[index] })) || [];
};

export const getRestrictionsByTOfferType = (
  offerRestrictions: OfferRestrictions | undefined | null,
  tOfferType?: OfferType,
): string[] => {
  return getRestrictions(offerRestrictions).filter(({ offerType }) => tOfferType === offerType).map(({ id }) => id);
};

export const genRanHex = (size: number): string => {
  const arr = new Uint8Array(Math.ceil(size / 2));
  crypto.getRandomValues(arr);
  return Array.from(arr, (byte) => byte.toString(16).padStart(2, '0')).join('').slice(0, size);
};

export const getExternalId = (): string => genRanHex(16);

export const getParsedErrorTransactions = (
  e: Web3ContractError | ErrorWithOriginal | Error,
): { message: string; transactionHash: string; } => {
  if (!e) return { message: '', transactionHash: '' };
  try {
    const error = (e as ErrorWithOriginal)?.originalError ?? e;
    const { transactionHash = '' } = (error as Web3ContractError)?.receipt || {};
    return {
      transactionHash: transactionHash as string,
      message: e?.message,
    };
  } catch (error) {
    return { message: e?.message, transactionHash: '' };
  }
};

export const getTransactionHashLink = (hash?: string): string => {
  if (!hash) return '';
  return `${getConfig().NEXT_PUBLIC_NETWORK_SCAN}/tx/${hash}`;
};

export const getErrorTransactionsTemplate = (e?: Error | Web3ContractError | string): string | ReactNode => {
  if (!e) return '';
  if (typeof e === 'string') return e;
  const parsedError = getParsedErrorTransactions(e);
  if (!parsedError?.transactionHash) return parsedError?.message;
  const link = getTransactionHashLink(parsedError?.transactionHash);
  return parsedError?.transactionHash
    ? <a href={link} target="_blank" rel="noopener noreferrer">transaction link</a>
    : parsedError?.message;
};

export function parseJwt<T>(token?: string | null): T | null {
  if (!token) return null;
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
}

export const getAddressFromAuthorizationToken = (token: string): string | undefined => {
  return parseJwt<{ address: string }>(token)?.address;
};

export const parseBackErrorMessage = (message: string): string => {
  const pos = message.indexOf(':');
  return pos > 0 ? message.slice(0, pos) : '';
};

/* eslint-disable no-promise-executor-return */
export const sleep = (ms: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, ms));

export const getTestIdFromTitle = (title?: string): string => {
  if (!title) return '';
  return title.toLocaleLowerCase().replace(/\s/g, '-');
};

export const isNumber = (value?: number | null): boolean => typeof value === 'number';

// export const isOfferSoon = (id: string): boolean => getConfig().NEXT_PUBLIC_OFFERS_SOON[id];
// export const isOfferBase = (id: string): boolean => getConfig().NEXT_PUBLIC_OFFERS_BASE[id];

// export const getOfferTextBtn = ({
//   id, enabled, inactive,
// }: { id: string, enabled: boolean, inactive?: boolean | null}): OfferTypeTextBtn | undefined => {
//   if (isOfferSoon(id)) {
//     return OfferTypeTextBtn.isOfferSoon;
//   }
//   if (isOfferBase(id)) {
//     return OfferTypeTextBtn.isOfferBase;
//   }
//   if (!enabled) {
//     return OfferTypeTextBtn.isNotEnabled;
//   }
//   if (inactive) {
//     return OfferTypeTextBtn.isOfferInactive;
//   }
// };

// export const isOfferApproved = (id: string, offerType: TOfferType): boolean => {
//   switch (offerType) {
//     case TOfferType.Solution:
//       return getConfig().NEXT_PUBLIC_APPROVED_OFFERS_SOLUTION?.[id];
//     case TOfferType.Storage:
//       return getConfig().NEXT_PUBLIC_APPROVED_OFFERS_STORAGE?.[id];
//     case TOfferType.Data:
//       return getConfig().NEXT_PUBLIC_APPROVED_OFFERS_DATA?.[id];
//     default:
//       return false;
//   }
// };

// export const isOfferSP = (id: string, offerType: TOfferType): boolean => {
//   switch (offerType) {
//     case TOfferType.Solution:
//       return getConfig().NEXT_PUBLIC_SUPERPROTOCOL_OFFERS_SOLUTION?.[id];
//     case TOfferType.Storage:
//       return getConfig().NEXT_PUBLIC_SUPERPROTOCOL_OFFERS_STORAGE?.[id];
//     case TOfferType.Data:
//       return getConfig().NEXT_PUBLIC_SUPERPROTOCOL_OFFERS_DATA?.[id];
//     default:
//       return false;
//   }
// };

// export const isOfferUnmoderated = (id: string, offerType: TOfferType): boolean => {
//   return !isOfferSP(id, offerType) && !isOfferApproved(id, offerType);
// };

// export const isOfferDisabled = (id: string, enabled: boolean): boolean => isOfferSoon(id) || isOfferBase(id) || !enabled;

export const getOfferTypeName = (tOfferType: TOfferType) => {
  switch (tOfferType) {
    case TOfferType.TeeOffer:
      return 'Compute';
    default:
      return tOfferType;
  }
};

export const isRelative = (path?: string) => path && /^\/[a-zA-Z]/.test(path);

export const getPathFromUrl = (url?: string): string => {
  if (!url) return '';
  return url.split('?')[0] || '';
};

export const deleteKeysFromObject = (obj: object, keys: string[]): object => {
  if (obj instanceof Object && Array.isArray(keys) && keys.length) {
    return keys.reduce((acc, key) => {
      delete acc[key];
      return acc;
    }, { ...obj });
  }
  return obj;
};

export const cleanVersion = (version: string): string => version.replace(/^[=v^~]+/, '');

export class ErrorWithOriginal<T = unknown> extends Error {
  public readonly originalError: T;
  constructor(originalError: T, message: string) {
    super(message);
    this.name = 'ErrorWithOriginal';
    this.originalError = originalError;
  }
}

export const getErrorMessage = (
  error?: string | Error | ErrorWithOriginal<{ data: { message: string } } | Error | string>,
): string => {
  if (!error) return '';
  if (typeof error === 'string') return error;
  return (error as ErrorWithOriginal<{ data: { message: string } }>).originalError?.data?.message
    || (error as ErrorWithOriginal<Error>)?.originalError?.message
    || error?.message
    || '';
};

export const checkDuplicateKeys = (arr: Record<string, any>[]): void => {
  if (!arr?.length) return;
  Object.keys(arr).reduce((acc, key) => {
    if (!acc[key]) {
      acc[key] = true;
    } else {
      throw new Error(`Duplicate key ${key}`);
    }
    return acc;
  }, {});
};

export const getReversedObj = <K extends string | number | symbol, V extends string | number | symbol>(
  obj: Record<K, V>,
): Record<V, K> => {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [value, key]),
  ) as Record<V, K>;
};